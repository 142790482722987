import React from "react";
import css from "./Core.module.scss";
import { Link } from "gatsby";

export const Background = ({ color, children }) => {
  return <div className={`${css.background} ${css[color]}`}>{children}</div>;
};
export function Container({ className, children }) {
  return <div className={`${css.container} ${className}`}>{children}</div>;
}
export function Button({ as, onClick, url, color, children }) {
  if (as === "link") {
    return (
      <Link className={`${css.button} ${css[color]}`} to={url}>
        {children}
      </Link>
    );
  } else if (as === "button") {
    return (
      <button onClick={onClick} className={[css.button, css.asbutton]}>
        {children}
      </button>
    );
  } else if (as === "a") {
    return (
      <a className={`${css.button} ${css[color]}`} href={url}>
        {children}
      </a>
    );
  } else if (as === "submit") {
    return (
      <button type="submit" className={[css.button, css.asbutton]}>
        {children}
      </button>
    );
  }
}
export const Slider = ({
  min,
  max,
  step,
  name,
  id,
  value,
  handle,
  text,
  style,
}) => {
  return (
    <div className={css.sliderGroup} style={style}>
      <input
        min={min}
        max={max}
        step={step}
        className={css.slider}
        type="range"
        name={name}
        id={id}
        value={value}
        onChange={handle}
      />
      <label htmlFor={id}>
        <b>{value}</b> {text}
      </label>
    </div>
  );
};
