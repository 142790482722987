import React from "react";
import { Background, Container } from "../../components/Core";
import css from "./Footer.module.scss";

const Footer = () => {
  return (
    <Background color="black">
      <Container>
        <div className={css.footer}>
          <div className={css.left}>
            <span>
              <b>VŠE PRO TISK s.r.o.</b>
            </span>
            <span>Budějovická 416, Písek 397 01</span>
            <br />
            <span>IČ: 280 69 153</span>
            <span>DIČ: CZ28069153</span>
          </div>
          <div className={css.center}>
            <a href="https://www.vseprotisk.cz">E-shop</a>
            <a href="https://blog.vseprotisk.cz">Blog</a>
          </div>
          <div className={css.right}>
            <b>
              Pronajmisitiskarnu.cz © 2023 VŠE&nbsp;PRO&nbsp;TISK&nbsp;s.r.o.
            </b>
            <p>
              Indikativní nabídky na tomto webu nejsou nabídkou ve smyslu §1734
              zákona č.89/2012 Sb., Občanského zákoníku, a jejím přijetím
              nevzniká mezi společností VŠE PRO TISK s.r.o. a druhou stranou
              závazkový vztah. Všechny ceny jsou uváděny bez DPH.
            </p>
          </div>
        </div>
      </Container>
    </Background>
  );
};

export default Footer;
