import React from "react";
import { Link } from "gatsby";
import { Container } from "../../components/Core";
import css from "./Topbar.module.scss";
import logo from "../../../img/logo.svg";
import logoSm from "../../../img/logo_dot.svg";

export default function Topbar() {
  return (
    <Container>
      <div className={css.topbar}>
        <Link to="/">
          <picture>
            <source media="(min-width: 1280px)" srcSet={logo} />
            <img src={logoSm} alt="Všeprotisk.cz" />
          </picture>
        </Link>
      </div>
    </Container>
  );
}
